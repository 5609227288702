import { useContext, useEffect, useState } from 'react';
import { SoTContext } from '../Global/SoTContext';
import { ActivityIndicator, StyleSheet, TouchableOpacity } from 'react-native';
import { View } from './Themed';
import Cropper from 'react-cropper';
import Modal from 'react-modal';
import 'cropperjs/dist/cropper.css';
import React from 'react';
import { globalStyles } from '../Global/GlobalStyles';

interface ShareImage {
  id?: number;
  uri?: string;
}

export default function ImagePickerBox({
  setSelectedPic,
  setShareImages,
  shareImage,
  index,
  selectedPic,
  setLoader,
}: {
  setSelectedPic: () => {};
  setShareImages: ({}) => {};
  shareImage: ShareImage;
  index: number;
  selectedPic: number;
  setLoader: () => {};
}) {
  const [loading, setLoading] = useState(false);
  const { api, user } = useContext(SoTContext);
  const [shareFaceImage, setShareFaceImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cropperInstance, setCropperInstance] = useState<any>();
  const [image, setCroppingImage] = useState('');
  
  useEffect(() => {
    setShareFaceImage(shareImage);
    setShareImages(prevState => {
      // Create a new array with spread operator
      const newArray = [...prevState];
      // Update the third object
      newArray[index] = shareImage;
      // Return the new array
      return newArray;
    });
  }, [shareImage])  

  const onFileSelected = (e: any) => {
    try {
      e.preventDefault();

      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setCroppingImage(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
      setShowModal(true);
    } catch (error) {
      console.log('error : ', error);
    }
  };

  const onCropComplete = async () => {
    try {
      setShowModal(false);
      setLoading(true);
      setLoader(true);
      if (typeof cropperInstance !== 'undefined') {
        const base64 = cropperInstance
          .getCroppedCanvas({ height: 800, width: 800 })
          .toDataURL('image/jpeg');
        console.log('base64 : ', base64);

        const photo = await api.uploadUserPhoto(user.id, { base64 });
        console.log('Cloud photo',photo);
        
        setShareFaceImage(photo);
        setShareImages(prevState => {
          // Create a new array with spread operator
          const newArray = [...prevState];
          // Update the third object
          newArray[index] = photo;
          // Return the new array
          return newArray;
        });
        setLoading(false);
        setLoader(false);

        setCroppingImage('');
      } else {
        console.log('crop : ', cropperInstance);
      }
    } catch (error) {
      console.log('onCropComplete error : ', error);
    }
  };

  const customStyles = {
    content: {
      top: 0,
      bottom: 0,
      padding: 0,
      left: 0,
      right: 0,
    },
  };

  return (
    <View style={styles.uploadBox}>
      {!loading && (
        <input
          style={{
            flex: 1,
            width: '100%',
            borderRadius: 4,
            opacity: 0,
            position: 'absolute',
            height: '100%',
            zIndex: 2,
          }}
          type="file"
          accept="image/*"
          onChange={onFileSelected}
        />
      )}
      <TouchableOpacity
        style={{ alignItems: 'center', position: 'absolute', zIndex: 9999, top: 10, right: 10 }}
        onPress={() => {
          if (Object.keys(shareFaceImage).length > 0) setSelectedPic(index);
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {selectedPic === index ? (
            <>
              <circle cx="12" cy="12" r="6" fill="#093F76" />
              <circle cx="12" cy="12" r="11" stroke="#093F76" strokeWidth="2" />
            </>
          ) : (
            <circle cx="12" cy="12" r="11" stroke="#6C757D" strokeWidth="2" />
          )}
        </svg>
      </TouchableOpacity>
      {loading ? (
        <ActivityIndicator size="large" />
      ) : shareFaceImage ? (
        <img
          src={shareFaceImage?.uri}
          style={{
            height: '100%',
            width: '100%',
            borderRadius: 6,
          }}
        />
      ) : (
        <>
          <svg
            width="55"
            height="55"
            viewBox="0 0 48 48"
            style={{ position: 'absolute' }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 39H13.5C10.7152 39 8.04451 37.8938 6.07538 35.9246C4.10625 33.9555 3 31.2848 3 28.5C3 25.7152 4.10625 23.0445 6.07538 21.0754C8.04451 19.1062 10.7152 18 13.5 18C14.3785 17.9997 15.2537 18.1067 16.1063 18.3188"
              stroke="#ADB5BD"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 24C15 21.6232 15.5648 19.2805 16.6479 17.1648C17.7309 15.0491 19.3012 13.2211 21.2293 11.8314C23.1575 10.4416 25.3883 9.53 27.7379 9.17156C30.0875 8.81312 32.4886 9.01815 34.7434 9.76975C36.9982 10.5214 39.0422 11.798 40.7068 13.4945C42.3714 15.191 43.609 17.2588 44.3177 19.5275C45.0264 21.7962 45.1858 24.2008 44.7828 26.5431C44.3799 28.8855 43.4261 31.0986 42 33"
              stroke="#ADB5BD"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.1436 30.3562L28.4998 24L34.8561 30.3562"
              stroke="#ADB5BD"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28.5 39V24"
              stroke="#ADB5BD"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      )}
      <Modal style={customStyles} isOpen={showModal}>
        <Cropper
          style={{
            height: '100%',
            backgroundColor: '#000000',
          }}
          dragMode="move"
          // allowFullScreen={true}
          // zoomTo={0}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          // initialAspectRatio={1}
          aspectRatio={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance: any) => {
            console.log('instancees : ', instance);

            setCropperInstance(instance);
          }}
          guides={true}
        />
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <button
            onClick={onCropComplete}
            style={{
              backgroundColor: '#0e467f',
              borderWidth: 0,
              color: 'white',
              padding: 10,
              fontFamily: 'Ubuntu Medium',
              fontSize: '1.2em',
              borderRadius: 80,
              height: 58,
              width: '80%',
              bottom: 50,
              zIndex: 1,
              position: 'absolute',
            }}
          >
            Crop Image
          </button>
        </div>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  uploadBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: globalStyles.container.width / 2.5,
    borderWidth: 2,
    borderRadius: 8,
    height: globalStyles.container.width / 2.5,
    borderColor: '#ADB5BD',
    marginBottom: 15,
  },
});
