import { ScrollView, StyleSheet, Image } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import TraitsMenu from '../components/Traits/TraitsMenu';
import Swiper from 'react-native-web-swiper';
import { SoTContext } from '../Global/SoTContext';
import html2canvas from 'html2canvas';
import { globalStyles } from '../Global/GlobalStyles';
import { SphereOfTrust } from '../Global/models.dto';

const slideWidth = globalStyles.container.width;

type Props = NativeStackScreenProps<SOTFlowType>;
export default function ReviewScreen({ navigation }: Props) {
  console.log('ReviewScreen');

  const {
    api,
    user,
    firstName,
    relationship,
    yearsKnown,
    ageCheck,
    bodyTypeCheck,
    heightCheck,
    educationCheck,
    jobTitleCheck,
    hasKidsCheck,
    smokesTobaccoCheck,
    personality,
    traitsPositive,
    traitsNeutral,
    traitsDominant,
    personalStoryInsights,
    oneSentenceInsights,
    completeTheSentenceInsights,
    imageData,
    setScreenshot,
    userExtraData,
    setUserExtraData,
    askAboutInsights
  } = useContext(SoTContext);

  const [traitList, setTraitList] = useState([]);

  useEffect(() => {
    const updatedTraits = traitsPositive
    ?.filter(t => t.selected)
    ?.map(t => t.label)
    .concat(traitsNeutral?.filter(t => t.selected)?.map(t => t.label))
    .map((t, i) => {
      if (t === traitsDominant) {
        return { label: t, selected: true, key: i };
      } else {
        return { label: t, selected: false, key: i };
      }
    })    

    setTraitList(updatedTraits);
  }, [traitsPositive, traitsNeutral, traitsDominant]);  

  const toTitleCase = (str: string) => {
    return str?.replace(/\b\w+/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const onAnimationStartHandler = (data: any) => {
    //console.log("anim start",data);
  };

  const onAnimationEndHandler = (data: any) => {
    //console.log("anim end", data);
  };

  const onIndexChangedHandler = (data: any) => {
    console.log('index change', data);
  };

  async function submit() {
    try {
      const req: SphereOfTrust = {
        firstName,
        relationship,
        yearsKnown: +yearsKnown?.replace(/\D/g, ''),
        personality: personality?.replace(/-/g, ''),
        positiveTraits: traitsPositive.filter(t => t.selected).map(t => t.label),
        neutralTraits: traitsNeutral.filter(t => t.selected).map(t => t.label),
        dominantTrait: traitsDominant,
        oneSentence: oneSentenceInsights,
        completeTheSentence: completeTheSentenceInsights,
        story: personalStoryInsights,
        valid: {
          age: ageCheck === 'true',
          body: bodyTypeCheck === 'true',
          height: heightCheck === 'true',
          education: educationCheck === 'true',
          occupation: jobTitleCheck === 'true',
          hasKids: hasKidsCheck === 'true',
          smoke: smokesTobaccoCheck === 'true',
        },
        photos: imageData,
        askAbout:askAboutInsights
      };

      const req2 = {
        ...userExtraData,
        completed: true,
        lastPage: 'Review',
        photos: imageData,
      };

      setUserExtraData(req2);
      console.log('save ', req2);
      await api.setStoredDetails(userExtraData?.id, req2);

      console.log('submit', req);
      await api.submitSoT(user.id, req);
    } catch (e) {
      alert(JSON.stringify(e));
    }

    // grab screenshot of summary
    // try {
    //   document.querySelector('#ds-review-scroller')?.scrollTo(0, 0);
    // } catch {
    /* scroll for print screen had issue */
    // }
    setTimeout(() => {
      navigation.navigate('ContestScreen');
    }, 250);
  }

  return (
    <View style={styles.container} nativeID="ds-review-page">
      <NavHeader
        screenName='Review'
        backButtonNavigation="ShareMedia"
        props={navigation}
        steps={[]}
        style={{ marginBottom: 20 }}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView nativeID="ds-review-scroller">
        <View style={{ height: slideWidth - 35, width: slideWidth, margin: 'auto' }}>
          <Swiper
            onAnimationStart={data => {
              return onAnimationStartHandler(data);
            }}
            onAnimationEnd={data => {
              return onAnimationEndHandler(data);
            }}
            onIndexChanged={data => {
              return onIndexChangedHandler(data);
            }}
            // slideWrapperStyle={{ width: slideWidth, margin: "auto" }}
            controlsProps={{
              prevTitle: '',
              nextTitle: '',
              dotActiveStyle: {
                borderColor: '#FFFFFF',
                borderWidth: 1,
                backgroundColor: '#093f76',
              },
              dotProps: {
                badgeStyle: {
                  backgroundColor: '#FFFFFF',
                },
              },
              dotsWrapperStyle: {
                marginBottom: 20,
              },
            }}
            key={imageData.map(image => image.uri).join(',')}
          >
            {imageData.map((imageItem, index) => (
              <View style={[styles.slideContainer, styles.slide]} key={index}>
                {/* <Text>{imageItem.name}</Text> */}
                <Image
                  style={{
                    width: globalStyles.container.width - 50,
                    height: globalStyles.container.width - 50,
                    borderRadius: 8,
                  }}
                  source={{ uri: imageItem.uri }}
                />
                {/* <View
                  style={{
                    paddingTop: 10,
                    position: 'absolute',
                    width: globalStyles.container.width - 30,
                    height: 60,
                    backgroundColor: '#0a4078',
                    opacity: 0.9,
                    bottom: 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 7,
                  }}
                >
                  <Text style={{ color: '#ffffff', fontFamily: 'Ubuntu', textAlign: 'center' }}>
                    imageItem.caption
                  </Text>
                </View> */}
              </View>
            ))}
          </Swiper>
        </View>
        <View style={styles.liftedSectionEmpty}></View>

        <View style={styles.liftedSection}>
          <View style={{ marginLeft: 30 }}>
            <Text
              style={{
                color: '#212529',
                fontFamily: 'Ubuntu Medium',
                fontSize: globalStyles.container.height < 800 ? 22 : 26,
                fontWeight: '500',
              }}
            >
              {firstName}
            </Text>
            <Text style={styles.subTitle}>
              {yearsKnown} Yrs - {relationship}
            </Text>
          </View>
        </View>

        <View style={styles.liftedSection}>
          <Text style={styles.liftedHeaderText}>Thinks {user?.first} is an</Text>
          <View style={{ flexDirection: 'row' }}>
            {/* <Smiley></Smiley> */}
            <Text style={{ ...styles.liftedBodyText }}>
              {toTitleCase(personality?.split('-').join('').toLowerCase())}
            </Text>
          </View>
        </View>

        <View style={[styles.liftedSection, { paddingBottom: 0 }]}>
          <Text style={[styles.liftedHeaderText, { padding: 0 }]}>Describes {user?.first} as</Text>
          <View style={{ flexWrap: 'wrap' }}>
            {traitList.length > 0 && <TraitsMenu atMax={true} optionItems={traitList}></TraitsMenu>}
          </View>
        </View>
        <View style={styles.liftedSection}>
          <Text style={styles.liftedHeaderText}>Personal story</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.liftedBodyText}>{personalStoryInsights} </Text>
          </View>
        </View>
        <View style={styles.liftedSection}>
          <Text style={styles.liftedHeaderText}>{user?.first} in one sentence</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.liftedBodyText}>"{oneSentenceInsights}"</Text>
          </View>
        </View>
        <View style={styles.liftedSection}>
          <Text style={styles.liftedHeaderText}>Count on it</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.liftedBodyText}>
              I can always count on {user.first} to{' '}
              <Text style={{ textDecorationLine: 'underline' }}>{completeTheSentenceInsights}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.liftedSection}>
          <Text style={styles.liftedHeaderText}>Ask {user?.first} about</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.liftedBodyText}>"{askAboutInsights}"</Text>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            paddingTop: 50,
            paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
            paddingHorizontal: 20,
          }}
        >
          <ButtonStandard
            // disabled={userExtraData.completed}
            label={userExtraData.completed ? "Update Intro" : "Submit Intro"}
            onPress={() => submit()}
          ></ButtonStandard>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  // },
  container: {
    flex: 1,
    // alignItems: 'flex-start',
    // justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  slideContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  slide: {
    width: slideWidth,
    height: slideWidth,
    borderRadius: 8,
    margin: 'auto',
  },
  separator: {
    marginTop: 30,
    height: 1,
    width: '80%',
  },
  liftedSection: {
    width: globalStyles.container.width,
    shadowColor: '#bbbbbb',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    paddingBottom: 15,
    paddingHorizontal: 20,
    paddingTop: 10,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  liftedSectionEmpty: {
    width: globalStyles.container.width,
    height: 2,
    // top: -50,
    shadowColor: '#bbbbbb',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 5,
    marginBottom: 0,
  },
  liftedHeaderText: {
    color: '#093F76',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    paddingRight: 20,
    fontFamily: 'Ubuntu Medium',
    paddingBottom: 10,
  },
  liftedBodyText: {
    color: '#333333',
    fontSize: 18,
    fontFamily: 'Jost Regular',
  },
  subTitle: {
    fontSize: 18,
    paddingTop: 5,
    fontWeight: '400',
    fontFamily: 'Jost Regular',
    color: '#6C757D',
  },
});
