import React, { useEffect, useState } from 'react';
import { DSAPI } from './api';
import { UserDto, PhotoProps } from './models.dto';
import { defaultPositiveTraits, defaultNeutralTraits } from '../Global/const';

export interface SoTResponse {
  api: DSAPI;

  user: UserDto;
  setUser: Function;

  userExtraData: object;
  setUserExtraData: Function;

  step: number;
  setStep: Function;

  // Area - Verify
  mobile: string;
  setMobile: Function;
  verificationId: string;
  setVerificationId: Function;

  // Area - Lets begin
  firstName: string;
  setFirstName: Function;
  relationship: string;
  setRelationship: Function;
  yearsKnown: string;
  setYearsKnown: Function;

  // Area - Checks
  ageCheck: string;
  setAgeCheck: Function;
  bodyTypeCheck: string;
  setBodyTypeCheck: Function;
  heightCheck: string;
  setHeightCheck: Function;
  educationCheck: string;
  setEducationCheck: Function;
  jobTitleCheck: string;
  setJobTitleCheck: Function;
  hasKidsCheck: string;
  setHasKidsCheck: Function;
  smokesTobaccoCheck: string;
  setSmokesTobaccoCheck: Function;

  // Area - Personality
  personality: string;
  setPersonality: Function;

  // Area - Traits
  traitsPositive: Array<Trait>;
  setTraitsPositive: Function;
  traitsNeutral: Array<Trait>;
  setTraitsNeutral: Function;
  traitsDominant: string;
  setTraitsDominant: Function;

  // Area - Insights
  oneSentenceInsights: string;
  setOnceSentenceInsights: Function;
  completeTheSentenceInsights: string;
  setCompleteTheSentenceInsights: Function;
  personalStoryInsights: string;
  setPersonalStoryInsights: Function;
  askAboutInsights: string;
  setAskAboutInsights: Function;

  // Area - Share media / Review
  imageData: Array<PhotoProps>;
  setImageData: Function;
  screenshot: string;
  setScreenshot: Function;
}

export interface Trait {
  label: string;
  selected: boolean;
}

const SoTContext = React.createContext({} as SoTResponse);

// TODO: KMD >> upon return to an incompleted survey, these values should be defaulted to the values returned from the server
const SoTProvider = ({ children }: any) => {
  const api = new DSAPI();

  const [user, setUser] = useState<UserDto>();
  const [userExtraData, setUserExtraData] = useState({});
  const [step, setStep] = useState(0);
  const [mobile, setMobile] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [yearsKnown, setYearsKnown] = useState('');
  const [ageCheck, setAgeCheck] = useState('');
  const [bodyTypeCheck, setBodyTypeCheck] = useState('');
  const [heightCheck, setHeightCheck] = useState('');
  const [educationCheck, setEducationCheck] = useState('');
  const [jobTitleCheck, setJobTitleCheck] = useState('');
  const [hasKidsCheck, setHasKidsCheck] = useState('');
  const [smokesTobaccoCheck, setSmokesTobaccoCheck] = useState('');
  const [personality, setPersonality] = useState('');
  const [traitsPositive, setTraitsPositive] = useState(defaultPositiveTraits);
  const [traitsNeutral, setTraitsNeutral] = useState(defaultNeutralTraits);
  const [traitsDominant, setTraitsDominant] = useState('');
  const [oneSentenceInsights, setOnceSentenceInsights] = useState('');
  const [completeTheSentenceInsights, setCompleteTheSentenceInsights] = useState('');
  const [personalStoryInsights, setPersonalStoryInsights] = useState('');
  const [askAboutInsights, setAskAboutInsights] = useState('');
  const [imageData, setImageData] = useState<PhotoProps[]>([]);
  const [screenshot, setScreenshot] = useState('');

  useEffect(() => {
    let logOutput = true;
    if (logOutput) {
      console.log('STATE', {
        userExtraData,
        user,
        step,
        mobile,
        verificationId,
        firstName,
        relationship,
        yearsKnown,
        ageCheck,
        bodyTypeCheck,
        heightCheck,
        educationCheck,
        jobTitleCheck,
        hasKidsCheck,
        smokesTobaccoCheck,
        personality,
        traitsPositive,
        traitsNeutral,
        traitsDominant,
        oneSentenceInsights,
        completeTheSentenceInsights,
        personalStoryInsights,
        imageData,
      });
    }
  }, [
    userExtraData,
    user,
    step,
    mobile,
    verificationId,
    firstName,
    relationship,
    yearsKnown,
    ageCheck,
    bodyTypeCheck,
    heightCheck,
    educationCheck,
    jobTitleCheck,
    hasKidsCheck,
    smokesTobaccoCheck,
    personality,
    traitsPositive,
    traitsNeutral,
    traitsDominant,
    oneSentenceInsights,
    completeTheSentenceInsights,
    personalStoryInsights,
    imageData,
  ]);

  return (
    <SoTContext.Provider
      value={{
        api,
        userExtraData,
        setUserExtraData,
        user,
        setUser,
        step,
        setStep,
        mobile,
        setMobile,
        verificationId,
        setVerificationId,
        firstName,
        setFirstName,
        relationship,
        setRelationship,
        yearsKnown,
        setYearsKnown,
        ageCheck,
        setAgeCheck,
        bodyTypeCheck,
        setBodyTypeCheck,
        heightCheck,
        setHeightCheck,
        educationCheck,
        setEducationCheck,
        jobTitleCheck,
        setJobTitleCheck,
        hasKidsCheck,
        setHasKidsCheck,
        smokesTobaccoCheck,
        setSmokesTobaccoCheck,
        personality,
        setPersonality,
        traitsPositive,
        setTraitsPositive,
        traitsNeutral,
        setTraitsNeutral,
        traitsDominant,
        setTraitsDominant,
        oneSentenceInsights,
        setOnceSentenceInsights,
        completeTheSentenceInsights,
        setCompleteTheSentenceInsights,
        personalStoryInsights,
        setPersonalStoryInsights,
        imageData,
        setImageData,
        screenshot,
        setScreenshot,
        askAboutInsights,
        setAskAboutInsights
      }}
    >
      {children}
    </SoTContext.Provider>
  );
};

export { SoTContext, SoTProvider };
